body {
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Outfit', sans-serif;
}
iframe {
  display: none;
}
